import React from 'react'
import ReactMarkdown from 'react-markdown'

const components: Record<string, React.FC<{ href?: string }>> = {
  h1: ({ children }) => <h1 className="markdown__heading markdown__heading--0">{children}</h1>,
  h2: ({ children }) => <h2 className="markdown__heading markdown__heading--1">{children}</h2>,
  h3: ({ children }) => <h3 className="markdown__heading markdown__heading--2">{children}</h3>,
  h4: ({ children }) => <h4 className="markdown__heading markdown__heading--3">{children}</h4>,
  h5: ({ children }) => <h5 className="markdown__heading markdown__heading--4">{children}</h5>,
  h6: ({ children }) => <h6 className="markdown__heading markdown__heading--5">{children}</h6>,
  p: ({ children }) => <p className="markdown__text">{children}</p>,
  a: ({ children, href }) => (
    <a href={href} className="markdown__link">
      {children}
    </a>
  ),
}

const Markdown: React.FC<{ children: string }> = ({ children }) => {
  return (
    <div className="markdown markdown__wrapper">
      <ReactMarkdown components={components} skipHtml={true}>
        {children}
      </ReactMarkdown>
    </div>
  )
}

export default Markdown
