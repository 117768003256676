import React from 'react'
import { useTranslation } from 'react-i18next'
import { VirtualTourBlock } from '../../../../../shared/types/cms'

const VirtualTourBlockComponent: React.FC<{ data: VirtualTourBlock }> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div className="page-block page-block-virtual-tour">
      <div className="container-lg">
        <p className="text-start">{t(`3d_tour_description`)}</p>
        <a href={data.url} target="_blank">
          <img src={data.image?.url} />
        </a>
      </div>
    </div>
  )
}

export default VirtualTourBlockComponent
